import React from 'react';
import { Flex, Image, Link, Spacer } from '@chakra-ui/react';
import discord from "../assets/social-media-icons/discord.png";
import Twitter from "../assets/social-media-icons/x.png";

const NavBar = () => {

    return (
        <Flex
            justify="center"
            align="center"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            zIndex={1} // Set overlay content to a higher z-index
            padding={10}
        >
            <Spacer />

            {/* Right Side - Social Media Icons */}
            <Flex justify="space-between" align="center" padding="10px">
                <Link href="https://www.discord.com" isExternal>
                    <Image src={discord} boxSize="69px" margin="0 15px" />
                </Link>
                <Link href="https://x.com/KajiPals" isExternal>
                    <Image src={Twitter} boxSize="69px" margin="0 15px" />
                </Link>
            </Flex>
        </Flex>
    )
};

export default NavBar;
