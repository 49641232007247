import './App.css';
import NavBar from './NavBar';
import backgroundImage from '../assets/background/landing.gif';
import roadmap from '../assets/background/roadmap.gif';
import { Box, Image } from '@chakra-ui/react';

function App() {
  return (
    <div className="App">
      <Box position="relative" textAlign="center" fontSize="xl">
        <Image src={backgroundImage} width="100%" />
        <NavBar />
        <Image src={roadmap} width="100%" />
      </Box>
    </div>
  );

}

export default App;
